export default [
  { path: '', component: () => import('layouts/App'), children: [
    {
      path: '', component: () => import('layouts/auth/Index'), children: [
        { path: '', name: 'auth', component: () => import('pages/auth/Auth'), },
        { path: '/registration', name: 'register', component: () => import('pages/auth/Rigister'), },
        { path: '/registration-step2', name: 'register2', component: () => import('pages/auth/RegisterStep2'), },
        { path: '/confirm-code', name: 'confirm.code', component: () => import('pages/auth/СonfirmationСode'), },
        { path: '/select-city', name: 'citySelect', component: () => import('pages/auth/SelectCityRegister'), },
        { path: '/enter-name', name: 'register.enterName', component: () => import('pages/auth/EnterNameRegister'), },
        { path: '/enter-data', name: 'register.enterData', component: () => import('pages/auth/EnterDataRegister'), },
        { path: '/select-category', name: 'register.selectCategory', component: () => import('pages/auth/SelectCategoryRegister'), },
      ], },
    {
      path: '', component: () => import('layouts/main/Index'), children: [
        { path: '/index', name: 'index', component: () => import('pages/index/Index'), },
        { path: '/orders', name: 'orders', component: () => import('pages/orders/Index'), },
        { path: '/profile', name: 'profile', component: () => import('pages/profile/Index'), },
        { path: '/my-app/:id', name: 'myApp', component: () => import('pages/my-app/Index'), },
        { path: '/profile/requests', name: 'profile.requests', component: () => import('pages/profile/requests/Index'), },
        { path: '/select-sub/:id', name: 'selectSub', component: () => import('pages/select-sub/Index'), },
        { path: '/newApplication', name: 'newApplication', component: () => import('pages/profile/new-application/Index'), },
        { path: '/create-app/:categoryId', name: 'createApp', component: () => import('pages/create-app/Index'), },
        { path: '/profile/my-data', name: 'my.data', component: () => import('pages/profile/my-data/Index'), },
        { path: '/profile/support', name: 'support', component: () => import('pages/profile/support/Support'), },
        { path: '/profile/faq', name: 'faq', component: () => import('pages/profile/faq/Index'), },
        { path: '/profile/security', name: 'security', component: () => import('pages/profile/security/Index'), },
        { path: '/profile/contacts', name: 'contacts', component: () => import('pages/profile/contacts/Contacts'), },
        { path: '/profile/my-city', name: 'myCity', component: () => import('pages/profile/my-city/Index'), },
        { path: '/profile/my-orders', name: 'my.orders', component: () => import('pages/profile/my-orders/Index'), },
        { path: '/profile/change-pin', name: 'change.pin', component: () => import('pages/profile/change-pin/Index'), },
        { path: '/profile/tariffs', name: 'tariffs', component: () => import('pages/profile/tariffs/Index'), },
        { path: '/profile/categories', name: 'profile.categories', component: () => import('pages/profile/categories/Index'), },
      ], },
  ], },
]

import BaseAuth from 'nast-auth'

/**
 *
 */
export default class NastAuth extends BaseAuth {
  login(user, token = undefined, grants = {}, redirect = true) {
    if (user.user && user.token) {
      this._store.commit('auth/login', user)
      redirect = user.redirect !== undefined ? user.redirect : true
    } else {
      this._store.commit('auth/login', { user, token, grants, })
    }
    this.apiLogin()
    $n.saveFcmToken().then(() => {
      if (redirect) {
        this.needAuth(false)
      }
    })
  }
}

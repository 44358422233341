/**
 * 
 */
export default class RestApi {
  auth = {
    login: (data) => $app.api.post([ 'auth/login', ]).data(data),
    register: (data) => $app.api.post([ 'auth/register', ]).data(data),
    info: () => $app.api.get([ 'auth/info', ]),
    checkPhone: (phone) => $app.api.post(['auth/check-phone*', phone]),
    checkPhoneReset: (phone) => $app.api.post(['auth/check-phone*/reset', phone]),
    sendSms: (phone) => $app.api.post(['auth/send-sms*', phone]),
    sendSmsReset: (phone) => $app.api.post(['auth/send-sms*/reset', phone]),
    checkSms: (phone, code) => $app.api.post(['auth/check-sms**', phone, code]),
    deleteAcc: () => $app.api.delete(['user/delete-acc']),
  }


  fcmPush = {
    save: (data) => $app.api.post('fcm-push/save').data(data),
    deleteToken: (data) => $app.api.delete('fcm-push/delete').data(data),
  }


  lang = {
    locales: (lang) => $app.api.get([ 'locales', ]),
    items: () => $app.api.get([ 'translations/content', ]),
  }

  user = {
    edit: (id, data) => $app.api.patch([ 'users*', id, ]).data(data),
  }
  tariffs = {
    get: (id) => $app.api.get([ 'tariffs*', id, ]),
  }
  categories = {
    get: (id) => $app.api.get([ 'categories*', id, ]),
    getChild: (id) => $app.api.get([ 'categories*/children', id, ]),
  }
  
  application = {
    get: (id) => $app.api.get([ 'apps*', id, ]),
    edit: (id, data) => $app.api.patch([ 'apps*', id, ]).data(data),
    myApp: () => $app.api.get([ 'my/apps', ]),
    myInactiveApp: () => $app.api.get([ 'my/apps/inactive', ]),
    myUserApp: () => $app.api.get([ 'respond/apps', ]),
    myUserInactiveApp: () => $app.api.get([ 'respond/apps/inactive', ]),
    myAppRequests: () => $app.api.get([ 'my/apps/requests', ]),
    create: (data) => $app.api.post([ 'apps', ]).data(data),
    confirm: (id) => $app.api.post([ 'apps*/confirm', id, ]),
  }


  files = {
    create: (data) => {
      const formData = new FormData()
      $n.each(data, (value, name) => {
        formData.append(name, value)
      })
      return $app.api.post([ 'files', ]).data(formData).config({ headers: { 'Content-Type': 'multipart/form-data', }, })
    },
  }
  
  faqs = {
    get: (id) => $app.api.get([ 'faqs', id, ]),
  }
  
  cities = {
    get: (id) => $app.api.get([ 'cities*', id, ]),
  }

  reviews = {
    create: (data) => $app.api.post([ 'reviews', ]).data(data),
  }
}
